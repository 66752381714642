import { useEffect, useRef, useState, useLayoutEffect } from 'react';
import ReactFullpage from "@fullpage/react-fullpage";
import ImageMapResizer from "image-map-resizer"
import ReactPlayer from "react-player/file"

//import { createGlobalState } from 'react-hooks-global-state';

//import { Helmet } from "react-helmet"
// import isFastNet from './isFastNet';

//import globalVars from './globalVars';

import "./assets.css";
import "./layout.css";




const pluginWrapper = () => {
  require('./fullpage.scrollHorizontally.min');
  require('./fullpage.continuousHorizontal.min');
};



const onLeave = (origin, destination, direction) => { }

const afterLoad = (origin, destination, direction) => { }

const afterSlideLoad = (origin, destination, direction) => { }

const onSlideLeave = (origin, destination, direction) => {
  const likesRow = document.querySelector('.likes-row');

  //console.log(direction.index)
  if (direction.index !== 0) {
    likesRow.style.display = "none"
    //console.log("none")
  } else {
    likesRow.style.display = "flex"
    //console.log("flex")
  }
}

const FullpageWrapper = () => (

  <ReactFullpage
    pluginWrapper={pluginWrapper}
    licenseKey={"20139BDA-D0D24247-9C32F105-EA898E58"}
    scrollOverflow={false}
    scrollingSpeed={800}
    continuousHorizontal={true}
    continuousHorizontalKey={"bWFya3N0ZXZlbnNtdXNpYy5jb21fejZLWTI5dWRHbHVkVzkxYzBodmNtbDZiMjUwWVd3PXZPSQ=="}
    scrollHorizontally={true}
    scrollHorizontallyKey={"bWFya3N0ZXZlbnNtdXNpYy5jb21fcGxJYzJOeWIyeHNTRzl5YVhwdmJuUmhiR3g1aEM1"}
    sectionsColor={["transparent"]}
    slidesNavigation={true}
    onLeave={onLeave}
    onSlideLeave={onSlideLeave}
    afterLoad={afterLoad}
    afterSlideLoad={afterSlideLoad}
    anchors={["page"]}

    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <div className="section">
            <div className="slide" id="page1">
              <div className="title">Mark A. Stevens, Hove - Musician</div>
              <div id="carouselC">
                <div className="carousel" >
                  <div className="carousel-face" ></div>
                  <div className="carousel-face" ></div>
                  <div className="carousel-face" ></div>
                  <div className="carousel-face" ></div>
                  <div className="carousel-face" ></div>
                  <div className="carousel-face" ></div>
                  <div className="carousel-face" ></div>
                  <div className="carousel-face" ></div>
                  <div className="carousel-face" ></div>
                </div>
              </div>
            </div>
            <div className="slide" id="page2">
              <div className="title">Listen everywhere...</div>
              <div className="storesC">
                <div className="stores-grid">
                  <a href="https://music.amazon.com/artists/B004CRJFUI/mark-a-stevens">
                    <div className="store-grid-item-name">Amazon Music</div>
                    <div className="store-grid-item" id="amazon-prime"></div>
                  </a>
                  <a href="https://music.apple.com/en/artist/mark-a-stevens/1611429814">
                    <div className="store-grid-item-name">Apple Music</div>
                    <div className="store-grid-item" id="apple"></div>
                  </a>
                  <a href="https://www.boomplay.com/artists/41426361">
                    <div className="store-grid-item-name">Boomplay Music</div>
                    <div className="store-grid-item" id="boomplay"></div>
                  </a>
                  <a href="https://www.claromusica.com/artist/777658/MX">
                    <div className="store-grid-item-name">Claro música</div>
                    <div className="store-grid-item" id="claro-musica"></div>
                  </a>
                  <a href="https://www.deezer.com/en/artist/2252281">
                    <div className="store-grid-item-name">Deezer</div>
                    <div className="store-grid-item" id="deezer"></div>
                  </a>
                  <a href="https://gaana.com/artist/mark-a-stevens">
                    <div className="store-grid-item-name">Gaana</div>
                    <div className="store-grid-item" id="gaana"></div>
                  </a>
                  <a href="https://www.iheart.com/artist/mark-j-stevens-342758/">
                    <div className="store-grid-item-name">iHeart Radio</div>
                    <div className="store-grid-item" id="iheart-radio"></div>
                  </a>
                  <a href="https://music.apple.com/en/artist/mark-a-stevens/405046496">
                    <div className="store-grid-item-name">iTunes</div>
                    <div className="store-grid-item" id="itunes"></div>
                  </a>
                  <a href="https://www.joox.com/th/artist/pdqqj20+pYwEvkJwOtwctQ==">
                    <div className="store-grid-item-name">JOOX</div>
                    <div className="store-grid-item" id="joox"></div>
                  </a>
                  <a href="https://www.kkbox.com/jp/ja/artist/YDb5eteXk6ou2j0F08TXk08K-index-1.html">
                    <div className="store-grid-item-name">KKBOX</div>
                    <div className="store-grid-item" id="kkbox"></div>
                  </a>
                  <a href="https://napster.com/artist/mark-a-stevens">
                    <div className="store-grid-item-name">Napster</div>
                    <div className="store-grid-item" id="napster"></div>
                  </a>
                  <a href="https://music.163.com/#/artist?id=51693371">
                    <div className="store-grid-item-name">NetEase Cloud</div>
                    <div className="store-grid-item" id="netease"></div>
                  </a>
                  <a href="https://www.pandora.com/artist/mark-a-stevens/ARxb7gnzvh652jk">
                    <div className="store-grid-item-name">Pandora</div>
                    <div className="store-grid-item" id="pandora"></div>
                  </a>
                  <a href="https://play.qobuz.com/artist/13054087">
                    <div className="store-grid-item-name">Qobuz</div>
                    <div className="store-grid-item" id="qobuz"></div>
                  </a>
                  <a href="https://www.shazam.com/en/artist/mark-a-stevens/405046496">
                    <div className="store-grid-item-name">Shazam</div>
                    <div className="store-grid-item" id="shazam"></div>
                  </a>
                  <a href="https://open.spotify.com/artist/1m5I5MTQ9EFBkUIhrE7RpK">
                    <div className="store-grid-item-name">Spotify</div>
                    <div className="store-grid-item" id="spotify"></div>
                  </a>
                  <a href="https://www.timmusic.it/artist/3794922">
                    <div className="store-grid-item-name">TIMMUSIC</div>
                    <div className="store-grid-item" id="timmusic"></div>
                  </a>
                  <a href="https://us.7digital.com/artist/mark-a-stevens">
                    <div className="store-grid-item-name">7digital</div>
                    <div className="store-grid-item" id="triller-7digital"></div>
                  </a>
                  <a href="https://musik.yousee.dk/kunstner/25047903">
                    <div className="store-grid-item-name">YouSee Musik</div>
                    <div className="store-grid-item" id="yousee-musik"></div>
                  </a>
                  <a href="https://www.youtube.com/channel/UCgKGqPZ5uRbdDx7iI5bF3ew">
                    <div className="store-grid-item-name">YouTube</div>
                    <div className="store-grid-item" id="youtube"></div>
                  </a>
                </div>
              </div>
            </div>
            <div className="slide" id="page3">
              <div className="title">The Singles, Vol. 1</div>

              <div className="singles-row">
                <div className="singles-column">
                </div>
                <div className="singles-column" id='singles1-col1'>
                  <div className="singles-cover singles1-cover-img" id='singles1-cover-img'>
                    <div className="youtube-play" >
                      <a id='singles1-ref' href="https://localhost">
                        <img id='youtube-play-img1' src="./img/youtube-play.png" alt="Play on Youtube" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="singles-column" id='singles1-col2'>
                  <img className="singles-image" id="singles1-image" src="./img/500/singles1.jpg" useMap="#singles1-map" alt="The Singles, Vol. 1" />
                  <div className="hand-pointer"></div>
                </div>
                <div className="singles-column">
                </div>
              </div>
              <map name="singles1-map" className="map-area">
                <area id="s1-1" shape="rect" coords="219,29,278,148" alt="Track 1" />
                <area id="s1-2" shape="poly" coords="351,53,405,89,335,185,283,147" alt="Track 2" />
                <area id="s1-3" shape="poly" coords="338,185,448,152,466,214,355,245" alt="Track 3" />
                <area id="s1-4" shape="poly" coords="358,252,464,285,444,345,338,312" alt="Track 4" />
                <area id="s1-5" shape="poly" coords="285,351,335,315,405,408,351,444" alt="Track 5" />
                <area id="s1-6" shape="rect" coords="215,353,281,469" alt="Track 6" />
                <area id="s1-7" shape="poly" coords="162,313,214,351,144,444,92,408" alt="Track 7" />
                <area id="s1-8" shape="poly" coords="29,287,142,250,161,312,49,348" alt="Track 8" />
                <area id="s1-9" shape="poly" coords="48,151,161,187,141,247,29,212" alt="Track 9" />
                <area id="s1-10" shape="poly" coords="92,89,144,53,214,146,162,185" alt="Track 10" />
              </map>
            </div>
            <div className="slide" id="page4">
              <div className="title">The Singles, Vol. 2</div>
              <div className="singles-row">
                <div className="singles-column">
                </div>
                <div className="singles-column" id='singles2-col1'>
                  <div className="singles-cover  singles2-cover-img" id="singles2-cover-img">
                    <div className="youtube-play" >
                      <a id='singles2-ref' href="https://localhost">
                        <img id='youtube-play-img2' src="./img/youtube-play.png" alt="Play on Youtube" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="singles-column" id='singles2-col2'>
                  <img className="singles-image" id="singles2-image" src="./img/500/singles2.jpg" useMap="#singles2-map" alt="The Singles, Vol. 2" />
                  <div className="hand-pointer"></div>
                </div>
                <div className="singles-column">
                </div>
              </div>
              <map name="singles2-map" className="map-area">
                <area id="s2-1" shape="rect" coords="219,29,278,148" alt="Track 1" />
                <area id="s2-2" shape="poly" coords="351,53,405,89,335,185,283,147" alt="Track 2" />
                <area id="s2-3" shape="poly" coords="338,185,448,152,466,214,355,245" alt="Track 3" />
                <area id="s2-4" shape="poly" coords="358,252,464,285,444,345,338,312" alt="Track 4" />
                <area id="s2-5" shape="poly" coords="285,351,335,315,405,408,351,444" alt="Track 5" />
                <area id="s2-6" shape="rect" coords="215,353,281,469" alt="Track 6" />
                <area id="s2-7" shape="poly" coords="162,313,214,351,144,444,92,408" alt="Track 7" />
                <area id="s2-8" shape="poly" coords="29,287,142,250,161,312,49,348" alt="Track 8" />
                <area id="s2-9" shape="poly" coords="48,151,161,187,141,247,29,212" alt="Track 9" />
                <area id="s2-10" shape="poly" coords="92,89,144,53,214,146,162,185" alt="Track 10" />
              </map>
            </div>
          </div>
        </ReactFullpage.Wrapper >
      );
    }}
  />
);

function App() {

  const player = useRef()
  const [tune, setTune] = useState("")
  const [play, setPlay] = useState(false)

  const ChangeTune = (tune) => {
    setTune(tune)
    player.current.seekTo(0)
    setPlay(true)
  }

  const ChangeCoverImage1 = (img, rotation, ref) => {

    const root = document.documentElement;
    const singles1CoverImg = document.getElementById("singles1-cover-img");
    const singles1Ref = document.getElementById("singles1-ref");

    root.style.setProperty(
      "--singles1-rotation", rotation
    );

    singles1CoverImg.classList.add('fade-out-in')

    const youTubePlayImg1 = document.getElementById("youtube-play-img1");
    youTubePlayImg1.style.setProperty(
      "display", "inline-block"
    );

    singles1Ref.href = ref

    setTimeout(function () {
      for (let i = 1; i < 11; i++) {
        let c = "s1-" + i
        singles1CoverImg.classList.remove(c)
      }
      singles1CoverImg.classList.add(img)
    }, 250);

    setTimeout(function () {
      singles1CoverImg.classList.remove('fade-out-in')
    }, 1010);
  }

  const ChangeCoverImage2 = (img, rotation, ref) => {

    const root = document.documentElement;
    const singles2CoverImg = document.getElementById("singles2-cover-img");
    const singles1Ref = document.getElementById("singles2-ref");

    root.style.setProperty(
      "--singles2-rotation", rotation
    );

    singles2CoverImg.classList.add('fade-out-in')

    const youTubePlayImg2 = document.getElementById("youtube-play-img2");
    youTubePlayImg2.style.setProperty(
      "display", "inline-block"
    );
    singles1Ref.href = ref


    setTimeout(function () {
      for (let i = 1; i < 11; i++) {
        let c = "s2-" + i
        singles2CoverImg.classList.remove(c)
      }
      singles2CoverImg.classList.add(img)
    }, 250);

    setTimeout(function () {
      singles2CoverImg.classList.remove('fade-out-in')
    }, 1010);
  }

  useEffect(() => {

    ImageMapResizer('[name="singles1-map"]')
    ImageMapResizer('[name="singles2-map"]')

    document.getElementById("s1-1").onclick = () => {
      ChangeCoverImage1("s1-1", "0deg", "https://www.youtube.com/watch?v=GDiXoUzoKCc&list=OLAK5uy_n7YiSo6c0kJsbNTbaHRlfLciPGQX_HtV0&index=1")
      ChangeTune("./mp3/breathless.mp3")
    }

    document.getElementById("s1-2").onclick = () => {
      ChangeCoverImage1("s1-2", "-36deg", "https://www.youtube.com/watch?v=fRJPaxo2N8g&list=OLAK5uy_n7YiSo6c0kJsbNTbaHRlfLciPGQX_HtV0&index=2")
      ChangeTune("./mp3/cascades.mp3")
    }

    document.getElementById("s1-3").onclick = () => {
      ChangeCoverImage1("s1-3", "-72deg", "https://www.youtube.com/watch?v=yvKMUK1LMaQ&list=OLAK5uy_n7YiSo6c0kJsbNTbaHRlfLciPGQX_HtV0&index=3")
      ChangeTune("./mp3/coming-home.mp3")
    }

    document.getElementById("s1-4").onclick = () => {
      ChangeCoverImage1("s1-4", "-108deg", "https://www.youtube.com/watch?v=jAXre9McK-A&list=OLAK5uy_n7YiSo6c0kJsbNTbaHRlfLciPGQX_HtV0&index=4")
      ChangeTune("./mp3/I-ll-tell-you-everything-bout-lovin.mp3")
    }

    document.getElementById("s1-5").onclick = () => {
      ChangeCoverImage1("s1-5", "-144deg", "https://www.youtube.com/watch?v=ZLWy0FLOHtY&list=OLAK5uy_n7YiSo6c0kJsbNTbaHRlfLciPGQX_HtV0&index=5")
      ChangeTune("./mp3/no-time-like-tomorrow.mp3")
    }

    document.getElementById("s1-6").onclick = () => {
      ChangeCoverImage1("s1-6", "-180deg", "https://www.youtube.com/watch?v=QXGkNnrnRcE&list=OLAK5uy_n7YiSo6c0kJsbNTbaHRlfLciPGQX_HtV0&index=6")
      ChangeTune("./mp3/strength.mp3")
    }

    document.getElementById("s1-7").onclick = () => {
      ChangeCoverImage1("s1-7", "-216deg", "https://www.youtube.com/watch?v=taAlHre4Uyc&list=OLAK5uy_n7YiSo6c0kJsbNTbaHRlfLciPGQX_HtV0&index=7")
      ChangeTune("./mp3/the-other-side.mp3")
    }

    document.getElementById("s1-8").onclick = () => {
      ChangeCoverImage1("s1-8", "-252deg", "https://www.youtube.com/watch?v=NjlIecDxZDs&list=OLAK5uy_n7YiSo6c0kJsbNTbaHRlfLciPGQX_HtV0&index=8")
      ChangeTune("./mp3/up-and-over.mp3")
    }

    document.getElementById("s1-9").onclick = () => {
      ChangeCoverImage1("s1-9", "-288deg", "https://www.youtube.com/watch?v=z8nRgdNaXuE&list=OLAK5uy_n7YiSo6c0kJsbNTbaHRlfLciPGQX_HtV0&index=9")
      ChangeTune("./mp3/who-you-are.mp3")
    }

    document.getElementById("s1-10").onclick = () => {
      ChangeCoverImage1("s1-10", "-324deg", "https://www.youtube.com/watch?v=rghFpB28BJ4&list=OLAK5uy_n7YiSo6c0kJsbNTbaHRlfLciPGQX_HtV0&index=10")
      ChangeTune("./mp3/you-are-my-everything.mp3")
    }

    document.getElementById("s2-1").onclick = () => {
      ChangeCoverImage2("s2-1", "0deg", "https://www.youtube.com/watch?v=6eiqhP0o8VQ&list=OLAK5uy_kog76Z79gBTIDOkPO46K66-HZIWhwz-0U")
      ChangeTune("./mp3/as-time-goes-by.mp3")
    }

    document.getElementById("s2-2").onclick = () => {
      ChangeCoverImage2("s2-2", "-36deg", "https://www.youtube.com/watch?v=gK_BuTznm-w&list=OLAK5uy_kog76Z79gBTIDOkPO46K66-HZIWhwz-0U&index=2")
      ChangeTune("./mp3/celestial-reverbs.mp3")
    }

    document.getElementById("s2-3").onclick = () => {
      ChangeCoverImage2("s2-3", "-72deg", "https://www.youtube.com/watch?v=_pW6f9VSLvA&list=OLAK5uy_kog76Z79gBTIDOkPO46K66-HZIWhwz-0U&index=3")
      ChangeTune("./mp3/chinese-checkers.mp3")
    }

    document.getElementById("s2-4").onclick = () => {
      ChangeCoverImage2("s2-4", "-108deg", "https://www.youtube.com/watch?v=t_SpVgS0egw&list=OLAK5uy_kog76Z79gBTIDOkPO46K66-HZIWhwz-0U&index=4")
      ChangeTune("./mp3/giddy-up.mp3")
    }

    document.getElementById("s2-5").onclick = () => {
      ChangeCoverImage2("s2-5", "-144deg", "https://www.youtube.com/watch?v=yxD9Plr6xSk&list=OLAK5uy_kog76Z79gBTIDOkPO46K66-HZIWhwz-0U&index=5")
      ChangeTune("./mp3/life-s-a-shame.mp3")
    }

    document.getElementById("s2-6").onclick = () => {
      ChangeCoverImage2("s2-6", "-180deg", "https://www.youtube.com/watch?v=NxshDEpxp74&list=OLAK5uy_kog76Z79gBTIDOkPO46K66-HZIWhwz-0U&index=6")
      ChangeTune("./mp3/monk-keys.mp3")
    }

    document.getElementById("s2-7").onclick = () => {
      ChangeCoverImage2("s2-7", "-216deg", "https://www.youtube.com/watch?v=tOOrgqmLru4&list=OLAK5uy_kog76Z79gBTIDOkPO46K66-HZIWhwz-0U&index=7")
      ChangeTune("./mp3/pirate-reggae.mp3")
    }

    document.getElementById("s2-8").onclick = () => {
      ChangeCoverImage2("s2-8", "-252deg", "https://www.youtube.com/watch?v=wkyQrlLyrHU&list=OLAK5uy_kog76Z79gBTIDOkPO46K66-HZIWhwz-0U&index=8")
      ChangeTune("./mp3/restless.mp3")
    }

    document.getElementById("s2-9").onclick = () => {
      ChangeCoverImage2("s2-9", "-288deg", "https://www.youtube.com/watch?v=gakjG31U-GU&list=OLAK5uy_kog76Z79gBTIDOkPO46K66-HZIWhwz-0U&index=9")
      ChangeTune("./mp3/scraggy-aggie.mp3")
    }

    document.getElementById("s2-10").onclick = () => {
      ChangeCoverImage2("s2-10", "-324deg", "https://www.youtube.com/watch?v=Z2Q0wsUks-E&list=OLAK5uy_kog76Z79gBTIDOkPO46K66-HZIWhwz-0U&index=10")
      ChangeTune("./mp3/try-to-help-yourself.mp3")
    }
  }, []);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  function GetWindowDimensions(props) {
    const [width, height] = useWindowSize();
    const root = document.documentElement;

    root.style.setProperty(
      "--pseudo-vh", height / 100 + "px"
    );

    root.style.setProperty(
      "--pseudo-vw", width / 100 + "px"
    );
  }


  /*   const CookieBotScript = "https://consent.cookiebot.com/uc.js"
    const FaceBookScript = "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v13.0"
    const TwitterScript = "https://platform.twitter.com/widgets.js" */

  return (
    <>


      {/*  <Helmet>
        <script id="Cookiebot" src={CookieBotScript} data-cbid="d4525eb0-df50-4908-af65-bcdfa08cc2fc" data-blockingmode="auto"></script>
        <script async crossOrigin="anonymous" src={FaceBookScript} nonce="CMjNrRwt"></script>
        <script defer src={TwitterScript}></script>
      </Helmet> */}
      <GetWindowDimensions />

      <div className="App">
        <div className="fullpageC"><FullpageWrapper /></div>
      </div>

      <div className='player-wrapper'>
        <ReactPlayer
          ref={player}
          className='react-player'
          url={tune}
          playing={play}
        />
      </div>


    </>
  );
}

export default App;